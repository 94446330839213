<template>
  <v-expansion-panel :readonly="disablePanel" class="segments-expansion-panel user-select">
    <v-expansion-panel-header class="px-0 d-flex flex-wrap flex-md-nowrap">
      <v-col md="1">
        {{ item.id }}
      </v-col>
      <v-col md="1" class="seg-image">
        <img v-show="item.imageUrl" :src="item.imageUrl" alt="segment image">
      </v-col>
      <v-col cols="10" md="4" lg="5">
        <div class="segments-accordion-header mb-1">
          {{ item.name }}
        </div>
        <div class="segments-accordion-subheader" :title="getDateWithDayNameAndTime(item.createdAt)">
          Created {{ getDate(item.createdAt) }}
        </div>
      </v-col>

      <v-col cols="2" class="notes">
        {{ item.notes }}
      </v-col>

      <v-col cols="2" class="hidden-md-and-up align-self-start">
        <v-icon v-if="item.type === '2'" size="20px" color="#1f9f43">
          mdi-alpha-d-circle
        </v-icon>
        <v-icon v-if="item.type === '1'" size="20px" color="#2b84eb">
          mdi-alpha-s-circle
        </v-icon>
      </v-col>

      <v-col cols="2">
        <span class="segments-accordion-counter">
          <span style="color: #2b84eb">{{ item.contactCount.toLocaleString() }}</span>
          contacts
          <v-menu v-if="isShowNewlyCreatedSegmentNoti" nudge-top="-5px" :offset-y="true" open-on-hover>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20px"
                color="#2b84eb"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <div class="newly-created-segment">
              The contact count may still be processing for this newly created segment.
            </div>
          </v-menu>
        </span>
      </v-col>

      <v-col class="hidden-sm-and-down" cols="auto" md="1">
        <v-menu nudge-top="-5px" :offset-y="true">
          <template #activator="{ on, attrs }">
            <v-icon
              v-if="item.type === '2'"
              v-bind="attrs"
              size="20px"
              color="#2b84eb"
              v-on="on"
            >
              mdi-alpha-d-circle
            </v-icon>
            <v-icon
              v-if="item.type === '1'"
              v-bind="attrs"
              size="20px"
              color="#1f9f43"
              v-on="on"
            >
              mdi-alpha-s-circle
            </v-icon>
            <!-- </div> -->
          </template>
          <SegmentTypeOuterList
            :icon-name="icon_name"
            :icon-color="icon_color"
            :title="title"
            :description="segmentTypeDescription"
          />
        </v-menu>
      </v-col>

      <template #actions>
        <div class="d-flex align-center">
          <div
            v-if="$store.getters['user/isReadOnly']"
            class="simple-text-button button--blue"
            @click="getSegmentDetails(item.id, index)"
          >
            {{ isOpened ? "Hide details" : "Show details" }}
          </div>

          <v-menu
            v-if="listItems.length"
            v-model="menuOpened"
            nudge-top="-5px"
            :nudge-left="getMenuNudgeLeft"
            :offset-y="true"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="elevation-0 outlined-btn px-lg-10 px-xl-15 ml-5 ml-lg-10 ml-xl-20"
                :class="{ 'panel-button--active': menuOpened }"
                height="34px"
                plain
                v-bind="attrs"
                v-on="on"
                @click="$emit('click')"
              >
                Actions
              </v-btn>
            </template>
            <OuterList
              title="Actions"
              :items="listItems"
              @click="$emit('action', { event: $event, item })"
            />
          </v-menu>
        </div>
      </template>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="px-0 py-0">
      <v-row class="px-0 py-0">
        <v-col class="d-flex flex-row flex-wrap">
          <v-col md="1" />
          <v-col sm="10" md="7" lg="6" xl="5">
            <v-row>
              <v-col
                v-for="it in panel_data"
                :key="it.subheader"
                class="d-flex justify-center align-start flex-column"
              >
                <div v-if="it.isArray">
                  <div v-if="it.header.length > 0">
                    <div v-for="sub in it.header" :key="sub" class="info-header-item">
                      {{ sub }}
                    </div>
                    <div class="info-subheader">
                      {{ it.subheader }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="info-header">
                    {{ it.header }}
                  </div>
                  <div class="info-subheader">
                    {{ it.subheader }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <!-- <v-col cols="0" md="4" /> -->
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";
import SegmentTypeOuterList from "@/views/Segments/components/SegmentTypeOuterList";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "Panel",
  components: { OuterList, SegmentTypeOuterList },
  mixins: [datesMixin],
  props: {
    item: {
      default: () => ({}),
    },
    isOpened: {
      default: () => {},
      type: Boolean,
    },
    index: {
      default: () => {},
      type: Number,
    },
  },
  data() {
    return {
      panel_data: [
      ],
      disablePanel: true,
      menuOpened: false,
    };
  },
  computed: {
    isShowNewlyCreatedSegmentNoti() {
      const createAt = new Date(this.item?.createdAt);
      const createAtTime = createAt.getTime();
      const timeNow = Date.now();
      const timeBetween = timeNow - createAtTime;
      const fiveMinuteMiliseconds = 5 * 60 * 1000;
      if (timeBetween < fiveMinuteMiliseconds) return true;
      return false;
    },
    icon_name: {
      get() {
        return this.item.type === "1"
          ? "mdi-alpha-s-circle"
          : "mdi-alpha-d-circle";
      },
    },
    icon_color: {
      get() {
        return this.item.type === "1" ? "#1f9f43" : "#2b84eb";
      },
    },
    title: {
      get() {
        return this.item.type === "1" ? "Static segment" : "Dynamic segment";
      },
    },
    segmentTypeDescription() {
      const staticType =
        "This segment can not change in size when new contacts are added or removed. To enable this feature, change to a Dynamic segment";
      const dynamicType =
        "This segment can change in size when new contacts are added or removed. To disable this, change to a Static segment";
      return this.item.type === "1" ? staticType : dynamicType;
    },
    getMenuNudgeLeft() {
      if (this.$vuetify.breakpoint.mdAndDown) return "80px";
      if (this.$vuetify.breakpoint.lgOnly) return "32px";
      return "0";
    },
    listItems() {
      const items = [];
      const canWrite = this.$store.getters['user/hasWritePermission'];
      const canRead = this.$store.getters['user/isReadOnly'];

      if (canWrite) {
        items.push(...[
          {
            label: "Edit Segment",
            icon: "mdi-pencil",
            value: "edit"
          },
          {
            label: "Add contacts",
            icon: "mdi-account-plus-outline",
            value: "addContacts",
          },
        ]);
      }

      if (canRead) {
        items.push(...[
          {
            label: "Export",
            icon: "mdi-export-variant",
            value: "export"
          },
          {
            label: "View Contacts",
            icon: "mdi-account",
            value: "viewContact",
          },
        ]);
      }

      if (canWrite) {
        items.push({
          label: "Archive Segment",
          icon: "mdi-delete-outline",
          value: "delete",
        });
      }

      return items;
    },
  },
  methods: {
    compareTop5Count(top5Data) {
      let sortTop5Data = top5Data.sort((a, b) => b.count -a.count);
      let contentData = []
      sortTop5Data.forEach((top5, i) => {
        if (i < 5) {
          contentData.push(`${i + 1}. ${top5.name}`);
        }
      });
      return contentData;
    },
    async getSegmentDetails(segmentId, index) {
      if (this.isOpened === false) {
        const resp = await this.$rest.segment.get_segment_statistics(segmentId);

        const openRate = parseFloat(resp.data?.open_rate ?? 0);
        const clickRate = parseFloat(resp.data?.unique_click_rate ?? 0);
        const leadScore = parseFloat(resp.data?.lead_score ?? 0);
        const preferredContent = resp.data?.top5.length > 0 ? this.compareTop5Count(resp.data?.top5) : [];
        this.panel_data = [
          {
            header: openRate > 0 ? openRate.toFixed(2) + "%" : "0%",
            subheader: "Open-Rate",
            isArray: false
          },
          {
            header: clickRate > 0 ? clickRate.toFixed(2) + "%" : "0%",
            subheader: "Click-Through-Rate",
            isArray: false
          },
          {
            header: leadScore > 0 ? leadScore.toFixed(2) : "0",
            subheader: "Avg.Lead Score",
            isArray: false
          },
          {
            header: preferredContent,
            subheader: "Preferred Content",
            isArray: true
          },
        ]
      }

      this.$emit('expand-or-hide-panel', index + 1);
    }
  },
};
</script>

<style lang="scss" scoped>
.segments-expansion-panel {
  .info-header {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b84eb;
    white-space: nowrap;
  }
  .info-header-item {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2b84eb;
    white-space: nowrap;
  }
  .info-subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  .segments-accordion-chip {
    height: 21px;
  }
  .v-expansion-panel-header {
    //cursor: context-menu !important;
  }
  .v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
    opacity: 0 !important;
  }
}

.newly-created-segment {
  width: 140px;
  padding: 20px;
  background-color: white !important;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}

.notes {
  white-space: pre-wrap;
}
.seg-image {
  max-width: 110px;
  img {
    max-width: 50px;
    max-height: 50px;
  }
}
.user-select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>
