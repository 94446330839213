<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      Add Contacts to a Segment
    </v-card-title>
    <v-divider />
    <validation-observer ref="textInput">
      <v-row style="margin: 0;">
        <v-col cols="12" class="px-6">
          <div class="field__header mt-2">
            File to Import
          </div>
          <v-file-input
            v-model="importFile"
            class="file-input"
            accept="text/csv"
            label="Select File to Import"
            small-chips
            hint="Upload a CSV (Comma-Delimited) file to add contacts to this segment"
            persistent-hint
            @change="setFile"
          />
        </v-col>
        <v-col
          v-if="importTemplateLink"
          cols="12"
          class="mt-3 px-6"
        >
          <a :href="importTemplateLink" target="_blank">Download a file template</a>
        </v-col>

        <v-col
          v-if="currentImports.length"
          cols="12"
          class="px-6"
        >
          <v-divider />
          <div class="field__header mt-3">
            Current Imports:
          </div>

          <ImportsTable
            :imports="currentImports"
          />
        </v-col>
        <v-col
          v-if="previousImports.length"
          cols="12"
          class="px-6"
        >
          <v-divider />
          <div class="field__header mt-3">
            <span v-if="!hasMoreThanThreePrevious">
              Previous Imports:
            </span>
            <span v-else>
              Last 3 Imports:
            </span>
          </div>

          <ImportsTable
            :imports="previousImports"
            :for-current-imports="false"
          />
        </v-col>
      </v-row>
    </validation-observer>

    <v-card-actions class="divider-with-shadow d-flex justify-end align-center px-6 py-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        @click="onImport"
      >
        Import
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ImportsTable from "@/views/Imports/components/ImportsTable"

export default {
  name: "SegmentEdit",
  components: {
    ImportsTable,
  },
  props: {
    segment:{
      default: () => {}
    },
  },
  data(){
    let importTemplateLink = '';
    const accountId = this.$store.getters['user/account']?.accountId;
    if (accountId) {
      let apiUrl = process.env.VUE_APP_REST_ADDRESS;
      apiUrl = (apiUrl.substr(-1) === '/') ? apiUrl.slice(0, -1) : apiUrl;
      importTemplateLink = apiUrl + '/imports/' + accountId + '/template/addToSegment'
    }

    return {
      id: '',
      eventStream: null,
      importType: 'addToSegment',
      importFile: null,
      importTemplateLink: importTemplateLink,
      currentImports: [],
      previousImports: [],
      completedStatuses: ['completed', 'canceled', 'failed'],
      previousStatuses: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
      hasMoreThanThreePrevious: false
    }
  },
  async created() {
    await this.subscribe();
    await this.getCurrentImports();
    await this.getPreviousImports();
  },
  async beforeDestroy() {
    if (!this.eventStream) {
      return;
    }

    this.currentImport = null;
    await this.$store.dispatch('mercure/unsubscribe', this.eventStream);
  },
  methods: {
    async subscribe() {
      this.eventStream = await this.$store.dispatch('mercure/import_type_subscribe', this.importType)
      this.eventStream.onmessage = (msg) => {
        this.$store.dispatch('mercure/update_import_type_last_event', {
          importType: this.importType,
          lastEventId: msg.lastEventId
        });

        const parsedMsg = JSON.parse(msg.data);

        if (parseInt(this.segment.id) !== parseInt(parsedMsg.importData.segmentId)) {
          return;
        }

        const existingImportIndex = this.currentImports.findIndex(i => parseInt(i.id) === parseInt(parsedMsg.id))
        if (existingImportIndex !== -1) {
          this.$set(this.currentImports, existingImportIndex, parsedMsg);
          return;
        }

        this.$set(this.currentImports, this.currentImports.length, parsedMsg);

        if (this.completedStatuses.includes(parsedMsg.status)) {
          this.$delete(this.currentImports, existingImportIndex);
          this.getPreviousImports();

          this.$emit('file-uploaded')
        }
      };
    },
    async getPreviousImports() {
      const resp = await this.$rest.imports.get_collection({
        importData: {
          segmentId: this.segment.id,
        },
        status: this.completedStatuses,
        sort: ['updatedAt:desc'],
        limit: 3
      });
      this.previousImports = resp.data.items;
      this.hasMoreThanThreePrevious = resp.data.totalCount;
    },
    async getCurrentImports() {
      const resp = await this.$rest.imports.get_collection({
        importData: {
          segmentId: this.segment.id,
        },
        status: this.previousStatuses,
        sort: ['createdAt:desc']
      });
      this.currentImports = resp.data.items;
    },
    async onImport() {
      const formData = new FormData();
      if (this.importFile) {
        formData.append('file', this.importFile);
      }
      formData.append('importData', JSON.stringify({segmentId: this.segment.id}));
      formData.append('importType', this.importType);

      await this.$rest.imports.post_resource(formData)
    },
    async setFile(file) {
      if (!(file instanceof File)) {
        return;
      }
      this.importFile = file
    },
  },
};
</script>

<style lang="scss" scoped>
  .popup-card {
    .field__header {
      margin-bottom: 15px;
    }
    .v-input.v-text-field {
      max-height: 44px;
    }
    .v-btn__content {
      text-transform: none;
    }
    .v-card__title {
      font-family: 'Open Sans', sans-serif;
      font-size: 15px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15;
    }
    fieldset {
      border-radius: 4px;
      border: solid 1px #dee7ee !important;
      background-color: #ffffff;
    }
    .input-label {
      font-family: 'Open Sans', sans-serif;
      font-size: 13px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15
    }
  }
  .file-input {
    label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      color: #66788e !important;
    }
  }
</style>
