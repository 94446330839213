<template>
  <v-expansion-panel class="expansion-panel-header" disabled>
    <v-expansion-panel-header class="px-0 d-flex flex-wrap flex-md-nowrap">
      <v-col cols="1">
        <div>ID</div>
      </v-col>
      <v-col cols="1" />
      <v-col cols="10" md="3" lg="4">
        <div>Name</div>
      </v-col>

      <v-col cols="2">
        <div>Notes</div>
      </v-col>

      <v-col cols="2">
        <div>Size</div>
      </v-col>

      <v-col cols="auto" md="1" />
    </v-expansion-panel-header>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "HeaderPanel",
};
</script>

<style lang="scss" scoped>
.v-expansion-panel.expansion-panel-header {
  border-top: none !important;
  height: 40px;

  .v-expansion-panel-header {
    cursor: context-menu !important;
    padding: 0;

    div {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      color: #241c15;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
