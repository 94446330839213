import { render, staticRenderFns } from "./SegmentsView.vue?vue&type=template&id=0d9e1fa2&scoped=true"
import script from "./SegmentsView.vue?vue&type=script&lang=js"
export * from "./SegmentsView.vue?vue&type=script&lang=js"
import style0 from "./SegmentsView.vue?vue&type=style&index=0&id=0d9e1fa2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d9e1fa2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VDialog,VExpansionPanels,VRow})
