<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Your Segments"
      regular-button-text="New segment"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    >
      <template #subheader>
        You have <a href="#">{{ segments_total }}</a> segment{{ segments_total !== 1 ? 's' : '' }}
      </template>
    </PageHeader>

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for segments"
      :current-page-number="segments_page_number"
      :current-page-size="segments_page_size"
      :count-total="segments_total"
      :page-sizes="[25, 50, 100]"
      @search="get_available_segments"
      @next-page="get_segments_next_page"
      @prev-page="get_segments_prev_page"
      @change-page-size="segments_change_page_size"
    />

    <v-row justify="center">
      <v-expansion-panels
        v-model="model"
        flat
        tile
        class="elevation-0"
        accordion
      >
        <HeaderPanel v-if="$vuetify.breakpoint.mdAndUp" />
        <Panel
          v-for="(item, i) in segments"
          :key="item.id"
          :item="item"
          :is-opened="model === i + 1"
          :index="i"
          @action="handleAction($event, item)"
          @expand-or-hide-panel="(index) => expandOrHidePanel(index)"
        />
      </v-expansion-panels>
    </v-row>
    <v-dialog v-model="dialog" scrollable max-width="560px" @click:outside="edited_segment = {}">
      <SegmentEdit
        v-if="dialog"
        :show-dynamic="false"
        :segment="edited_segment"
        @save="save_segment"
        @dismiss="dialog = false; edited_segment = {}"
      />
    </v-dialog>
    <v-dialog v-model="delete_dialog" scrollable max-width="360px" @click:outside="edited_segment = {}">
      <DeleteConfirmationDialog
        v-if="delete_dialog"
        header="Archive segment"
        subheader="Do you really want to archive segment?"
        action_button_name="Archive"
        @delete="delete_segment(delete_item_id)"
        @dismiss="delete_dialog = false; delete_item_id = false;"
      />
    </v-dialog>
    <v-dialog v-model="showUploadDialog" scrollable max-width="860px" @click:outside="uploadToSegment = {}">
      <SegmentUploadDialog
        v-if="showUploadDialog"
        :segment="uploadToSegment"
        @file-uploaded="get_available_segments"
        @dismiss="showUploadDialog = false; uploadToSegment = {};"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Panel from "@/views/Segments/components/Panel";
import HeaderPanel from "@/views/Segments/components/HeaderPanel";
import SegmentUploadDialog from"@/views/Segments/components/SegmentUploadDialog";
import PageHeader from "@/sharedComponents/PageHeader";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import SegmentEdit from '@/views/Segments/components/SegmentEdit';
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "SegmentsView",
  metaInfo: {
    title: 'Segments'
  },
  components: {
    Panel,
    PageHeader,
    SearchFieldRowWithPagination,
    SegmentUploadDialog,
    HeaderPanel,
    SegmentEdit,
    DeleteConfirmationDialog
  },
  data() {
    return {
      dialog: false,
      edited_segment: {},
      model: undefined,
      segments: [],
      segments_total: 0,
      search_string: "",
      delete_dialog: false,
      delete_item_id: false,
      segments_page_number: 1,
      segments_page_size: 25,
      showUploadDialog: false,
      uploadToSegment: {}
    };
  },
  created() {
    this.get_available_segments();
  },
  methods: {
    async save_segment(segment) {
      if (segment.id){
        await this.$rest.segment.put_resource(segment.id, segment)
      } else {
        segment.criteria =  {
          'operator': 'AND',
          'groups': []
        }
        await this.$rest.segment.post_resource(segment)
      }

      this.dialog = false;
      await this.get_available_segments();

      const message = (segment.id) ? 'Updated' : 'Created';

      this.$notifier.success(`Successfully ${message} Segment - ${segment.name}`);
      this.edited_segment = {};
    },
    expandOrHidePanel(index) {
      index === this.model ? (this.model = undefined) : (this.model = index);
    },
    async delete_segment(id) {
      await this.$rest.segment.put_resource(id, {
        ...this.edited_segment,
        isArchived: true,
      })
      // await this.$rest.segment.delete_resource(id);
      await this.get_available_segments();
      this.delete_dialog = false;
      this.delete_item_id = null;
      this.$notifier.info("Successfully deleted the segment.");
      this.edited_segment = {};
    },
    async handleAction(action, item) {
      if (action.event.value === "delete") {
        this.edited_segment = { ...action.item};
        this.delete_item_id = action.item.id;
        this.delete_dialog = true;
      } else if (action.event.value === "edit") {
        this.edited_segment = { ...action.item};
        this.dialog = true;
      } else if (action.event.value === "viewContact") {
        await this.$router.push({
          name: "ContactsSearch",
          query: { segmentId: item.id }
        });
      } else if (action.event.value === "export") {
        await this.$rest.exports.post_resource({
          "exportType": "contact",
          "exportData": {
            "segmentId": action.item.id,
          },
        })
        await this.$router.push({name: "ExportsView"})
      } else if (action.event.value === "addContacts") {
        this.showUploadDialog = true;
        this.uploadToSegment = {...action.item};
      }
    },
    async segments_change_page_size(size) {
      this.segments_page_size = size;
      this.segments_page_number = 1;
      await this.get_available_segments();
    },
    async get_segments_next_page() {
      this.segments_page_number += 1;
      await this.get_available_segments();
    },
    async get_segments_prev_page() {
      this.segments_page_number -= 1;
      await this.get_available_segments();
    },
    async get_available_segments(search_string) {
      if (search_string) {
        this.search_string = search_string;
      } else {
        this.search_string = "";
      }
      const resp = await this.$rest.segment.get_collection({
        limit: this.segments_page_size,
        page: this.segments_page_number,
        name: this.search_string,
        sort: ['name:asc'],
        isArchived: false,
      });
      this.segments = resp.data.items;
      this.segments_total = resp.data.totalCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.segments-accordion {
  ::v-deep .v-expansion-panel {
    &:first-child {
      border-top: solid 1px #dee7ee;
    }
    border-bottom: solid 1px #dee7ee;
  }
  ::v-deep .v-expansion-panel-header {
    cursor: auto !important;
  }
  ::v-deep &-header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  ::v-deep &-subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #66788e;
  }
  ::v-deep &-counter {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  ::v-deep &-chip {
    border-radius: 10.5px;
    .v-chip__content {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b84eb;
    }
  }
}
</style>
